import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationInputControlComponent } from './duration-input-control.component';
import { DurationInputControlDirective } from './duration-input-control.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DurationInputControlComponent, DurationInputControlDirective],
  imports: [CommonModule, FormsModule, TranslateModule, ReactiveFormsModule],
  exports: [DurationInputControlComponent, DurationInputControlDirective],
})
export class DurationInputControlModule {}
